import { Button, TextField, Typography } from "@mui/material";
import { Formik } from 'formik';
import { FC, useEffect } from "react";
import { PHONE_NUMBER, WHATSAPP_URL } from "../../constants";
import { BackgroundBox, BoxWithFilter, FormBox, TextBox } from "./Components";


type ContactForm = {
  name: string;
  email: string;
  phone: string;
  comments: string;
};

type MessageParams = {
  name: string,
  email: string,
  phoneNumber: string,
  message?: string
}
function formMessage({ name, email, phoneNumber, message }: MessageParams) {
  return `Hola. Quisiera información especializada\n' +
    'Nombre: ${name}\nCorreo Electrónico: ${email}\nNúmero telefónico: ${phoneNumber}\nMensaje: ${message}`;
}



const Contact: FC<{}> = () => {
  useEffect(() => {
    window.addEventListener('error', e => {
      if (e.message === 'ResizeObserver loop limit exceeded') {
        const resizeObserverErrDiv = document.getElementById(
          'webpack-dev-server-client-overlay-div'
        );
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.setAttribute('style', 'display: none');
        }
        if (resizeObserverErrDiv) {
          resizeObserverErrDiv.setAttribute('style', 'display: none');
        }
      }
    });
  }, []);

  return (
    <>
      <BackgroundBox className="contact-background" >
        <BoxWithFilter>
          <TextBox>
            <Typography variant="h2" fontWeight="light">Portal de empresas</Typography>
            <br />
            <Typography variant="h6">¿Te preocupa el bienestar de tus colaboradores?
              Asegúrate de que estén en óptimas condiciones con nuestro seguimiento de
              salud oportuno.
            </Typography>
            <Typography variant="h6">
              Deja tus datos y te contactaremos vía whatsapp
            </Typography>
          </TextBox>
          <FormBox>
            <Formik
              initialValues={{
                name: '',
                email: '',
                phone: '',
                comments: ''
              }}
              validate={values => {
                const errors = {} as Partial<ContactForm>;
                if (!values.email) {
                  errors.email = 'Escribe un correo electrónico';
                } else if (
                  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                ) {
                  errors.email = 'Correo inválido';
                }

                if (!values.name) {
                  errors.name = 'Escribe un nombre';
                }

                if (!values.phone) {
                  errors.phone = 'Escribe un número de teléfono';
                } else if (
                  !/^(\+?)[0-9]{10,13}$/.test(values.phone)
                ) {
                  errors.phone = 'Número inválido';
                }

                return errors;
              }}
              onSubmit={(values, helpers) => {
                const errors: any = {};
                if (!values.phone) {
                  errors.phone = 'set a phone';
                }

                if (Object.keys(errors).length) {
                  return errors;
                }

                const { name, phone, email, comments } = values;
                const message = formMessage({ name, email, phoneNumber: phone, message: comments });

                // send logic
                window.open(`${WHATSAPP_URL}/${PHONE_NUMBER}?text=${message}`, '_blank');
                helpers.setSubmitting(false);

              }}
            >
              {({ values, errors, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => (
                <form onSubmit={handleSubmit}>
                  <TextField
                    name="name"
                    value={values.name}
                    label="Nombre"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.name}
                    placeholder="Ej. Juan" />

                  <TextField
                    name="email"
                    value={values.email}
                    label="Correo electrónico"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.email}
                    placeholder="contacto@empresa.com" />

                  <TextField
                    name="phone"
                    value={values.phone}
                    label="Teléfono"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!errors.phone}
                    placeholder="5512345678" />

                  <TextField
                    sx={{ '* > textarea': { padding: 2 } }}
                    name="comments"
                    value={values.comments}
                    multiline
                    rows={5}
                    label="Comentarios"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Ej. Juan" />
                  <Button type="submit" variant="contained" color="light" disabled={isSubmitting || !isValid}>Enviar</Button>
                </form>
              )}
            </Formik>
          </FormBox>
        </BoxWithFilter>

      </BackgroundBox>
    </>
  );
};

export default Contact;