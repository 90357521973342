import { Box, styled } from "@mui/material";

export const BackgroundBox = styled(Box)(({ theme }) => ({
  background: 'url(/banner-empresas-lg.png)',
  height: 550,
  backdropFilter: 'brightness(0) saturate(100%) invert(19%) sepia(74%) saturate(2257%) hue-rotate(203deg) brightness(97%) contrast(96%) opacity(20%)',
  [theme.breakpoints.down('md')]: {
    background: 'url(/banner-empresas-xs.png)',
    height: 168,
    marginBottom: '150%'
  }
}));

export const BoxWithFilter = styled(Box)(({ theme }) => ({
  backdropFilter: 'brightness(0) saturate(100%) invert(19%) sepia(74%) saturate(2257%) hue-rotate(203deg) brightness(97%) contrast(96%) opacity(60%)',
  display: 'flex',
  height: '100%',
  width: '100%',
  '> *': {
    color: 'white'
  },
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column'
  }
}));

export const TextBox = styled(Box)(({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  padding: theme.spacing(5),
  textAlign: 'left',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1),
    width: '90%'
  }
}));

export const FormBox = styled(Box)(({ theme }) => ({
  background: 'white',
  border: `1px solid ${theme.palette.grey[100]}`,
  borderRadius: theme.spacing(1),
  boxShadow: '2px 2px 14px 0px rgba(0, 0, 0, 0.10)',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  padding: theme.spacing(6),
  paddingBottom: theme.spacing(10),
  position: 'relative',
  top: theme.spacing(7),
  width: 520,
  '& form': {
    display: 'flex',
    flex: '0.8',
    flexDirection: 'column',
    rowGap: theme.spacing(3),
  },
  [theme.breakpoints.down('md')]: {
    marginTop: theme.spacing(7),
    padding: `${theme.spacing(3)} 0`,
    top: 0,
    maxWidth: '100%'
  }
}));