export const Paths = {
  BUDGETIZER: '/cotizador',
  CATALOG: '/catalogo',
  CONTACT: '/empresas',
  COVID_TESTS: 'pruebas-covid',
  DETAILS: '/estudio/:sku',
  MEMBERSHIPS: '/membresias',
  RESULTS: '/resultados',
  ROOT: '/',
  US: '/nosotros',
  DEFAULT: '*',
}